<template>
  <Page  :title="'法考服务'">
    <article id="page-assistance" class="business_banner">
      <section class="container container-main">
        <div class="business-banner-head">
          <h1 class="title">国家统一法律职业资格考试</h1>
          <p class="desc">
            国家统一法律职业资格考试实际上是指国家司法考试，是中华人民共和国司法部依据《中华人民共和国法官法》《中华人民共和国检察官法》 《中华人民共和国律师法》《中华人民共和国公证法》及《国家司法考试实施办法》的有关规定设立的职业证书考试。
          </p>
          <span class="business-banner-head-icon flyz-icon"></span>
        </div>
        <div class="bg_white w-1200 br-4 p-3">
          <div class="subtitle">
            <h2>国家统一法律职业资格考试指南</h2>
            <hr class="underline" />
          </div>
        <div class="comm-html">
        <a-tabs
          default-active-key="condition"
          :animated="false"
          class="block-tabs mt-5"
        >
          <a-tab-pane key="condition" tab="报名条件">
            <div class="ov_text">
              <h4>报名条件</h4>
              <p>符合以下条件的人员，可以报名参加国家统一法律职业资格考试:</p>
              <p>(一)具有中华人民共和国国籍;</p>
              <p>(二)拥护中华人民共和国宪法，享有选举权和被选举权;</p>
              <p>(三)具有良好的政治、业务素质和道德品行;</p>
              <p>(四)具有完全民事行为能力;</p>
              <p>(五)具备全日制普通高等学校法学类本科学历并获得学士及以上学位;全日制普通高等学校非法学类本科及以上学历，并获得法律硕士、法学硕士及以上学位;全日制普通高等学校非法学类本科及以上学历并获得相应学位且从事法律工作满三年。</p>
              <p>有下列情形之一的人员，不得报名参加国家统一法律职业资格考试:</p>
              <p>(一)因故意犯罪受过刑事处罚的;</p>
              <p>(二)曾被开除公职或者曾被吊销律师执业证书、公证员执业证书的;</p>
              <p>(三)被吊销法律职业资格证书的;</p>
              <p>(四)被给予二年内不得报名参加国家统一法律职业资格考试(国家司法考试)处理期限未满或者被给予终身不得报名参加国家统一法律职业资格考试(国家司法考试)处理的;</p>
              <p>(五)因严重失信行为被国家有关单位确定为失信联合惩戒对象并纳入国家信用信息共享平台的;</p>
              <p>(六)因其他情形被给予终身禁止从事法律职业处理的。</p>
              <p>有前款规定情形之一的人员，已经办理报名手续的，报名无效;已经参加考试的，考试成绩无效。</p>
            </div>
          </a-tab-pane>
          <a-tab-pane key="material" tab="考试内容和方式">
            <div class="ov_text">
              <h4>考试内容和方式</h4>
              <p>第十一条 国家统一法律职业资格考试的具体考试时间和相关安排在举行考试三个月前向社会公布。</p>
              <p>第十二条 国家统一法律职业资格考试实行全国统一命题。</p>
              <p>国家统一法律职业资格考试的内容和命题范围以司法部当年公布的《国家统一法律职业资格考试大纲》为准。</p>
              <p>第十三条 国家统一法律职业资格考试每年举行一次，分为客观题考试和主观题考试两部分，综合考查应试人员从事法律职业应当具有的政治素养、业务能力和职业伦理。</p>
              <p>应试人员客观题考试成绩合格的方可参加主观题考试，客观题考试合格成绩在本年度和下一个考试年度内有效。</p>
              <p>第十四条 国家统一法律职业资格考试实行纸笔考试或者计算机化考试。</p>
              <p>第十五条 国家统一法律职业资格考试实行全国统一评卷，统一确定合格分数线，考试成绩及合格分数线由司法部公布</p>
            </div>
          </a-tab-pane>
          <a-tab-pane key="range" tab="资格授予和管理">
            <div class="ov_text">
              <h4>资格授予和管理</h4>
              <p>第十八条 参加国家统一法律职业资格考试成绩合格，且不具有本办法第十条第一款规定情形的人员，可以按照规定程序申请授予法律职业资格，由司法部颁发法律职业资格证书。</p>
              <p>第十九条 以欺骗、贿赂等不正当手段取得法律职业资格证书的，由司法部撤销原授予法律职业资格的决定，注销其法律职业资格证书。</p>
              <p>第二十条 取得法律职业资格人员有违反宪法和法律、妨害司法公正、违背职业伦理道德等行为的，由司法行政机关根据司法部有关规定，视其情节、后果，对其给予相应处理。</p>
              <p>第二十一条 司法行政机关应当将取得法律职业资格人员的有关信息，以及依据本办法第十九条、第二十条作出相应处理人员的有关信息，录入国家法律职业资格管理系统，在司法部官方网站上公布。</p>
            </div>
          </a-tab-pane>
        </a-tabs></div>
        <div class="entrance">
          <a href="http://www.moj.gov.cn/organization/content/2019-06/04/kszxdt_236458.html" class="enter_img" target="_blank"> <img src="../../assets/images/enter-4.png"> </a>
          <a href="http://www.moj.gov.cn/organization/content/2019-06/04/kszxdt_236458.html" class="enter_img" target="_blank"> <img src="../../assets/images/enter-2.png"> </a>
          <a href="http://www.moj.gov.cn/organization/content/2019-06/04/kszxdt_236458.html" class="enter_img" target="_blank"> <img src="../../assets/images/enter-1.png"> </a>
          <a href="http://www.moj.gov.cn/organization/content/2019-06/04/kszxdt_236458.html" class="enter_img" target="_blank"> <img src="../../assets/images/enter-3.png"> </a> </div>

            <div class="tab float-left w-news-tab col-lg-6 col-md-6 pl-0 mb-4">
               <a-tabs default-active-key="1">
                   <a-tab-pane key="1" tab="在线课堂">
                     <div  class="tab-pane">
                    <Collection  name="post" :query="{ target: '0176142973f28a8380377603faf90828' }"
                      v-slot="{ collection }"  :limit=9 >
                    <Article :refObj="collection"></Article>
                    </collection>
                    </div>
                   </a-tab-pane>
                    <a-tab-pane key="2" tab="法考公告">
                      <div  class="tab-pane">
                   <Collection  name="post"   :query="{ target: '017614292dab8a8380377603faf90826' }"
                    v-slot="{ collection }"  :limit=9 >
                   <Article :refObj="collection"></Article>
                   </collection>
                  </div>
                    </a-tab-pane>
                     <a-tab-pane key="3" tab="备战经验">
                      <div  class="tab-pane">
                   <Collection  name="post"   :query="{ target: '0176898c5a618a838128768809590cdc' }"
                    v-slot="{ collection }"  :limit=9 >
                   <Article :refObj="collection"></Article>
                   </collection>
                  </div>
                    </a-tab-pane>
                 </a-tabs>
            </div>
            <div class="tab float-right w-news-tab col-lg-6 col-md-6 pr-0 mb-4">
               <a-tabs default-active-key="1">
                   <a-tab-pane key="1" tab="法考咨询">
                     <div  class="tab-pane">
                    <Collection  name="post" :query="{ target: '0176898ef7c98a838128768809590cde' }"
                      v-slot="{ collection }"  :limit=9 >
                    <Article :refObj="collection"></Article>
                    </collection>
                    </div>
                   </a-tab-pane>
                    <a-tab-pane key="2" tab="常见问题解答">
                      <div  class="tab-pane">
                   <Collection  name="post"   :query="{ target: '017689a32b178a838128768809590ecf' }"
                    v-slot="{ collection }"  :limit=9 >
                   <Article :refObj="collection"></Article>
                   </collection>
                  </div>
                    </a-tab-pane>
                    <a-tab-pane key="3" tab="政策法规">
                    <div  class="tab-pane">
                  <Collection  name="law" :query="{ law_ctype: '法律资格考试' }"
                    v-slot="{ collection }"  :limit=9 >
                  <Law :refObj="collection"></Law>
                  </collection>
                  </div>
                  </a-tab-pane>
                 </a-tabs>
            </div>
        </div>
      </section>
    </article>
  </Page>
</template>
<script>
import Collection from '@/components/Collection'
import Page from '@/components/base/Page'
import Article from '../../components/base/Article.vue'
import Law from '../../components/base/Law.vue'
export default {
  components: {
    Collection,
    Page,
    Article,
    Law
  },
  data () {
    return {
      provider: {
        current: 1,
        defaultPageSize: 12
      },
      providerQuery: {
        city: '',
        area: '',
        provider_name: ''
      }
    }
  },
  methods: {
    onChange (page, pageSize) {
      this.provider.current = page
    },
    handleCityChange (obj) {
      this.providerQuery.city = obj.city
      this.providerQuery.area = obj.area
    }
  }
}
</script>
